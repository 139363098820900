import React from "react";
import { Card, Button,Col } from 'react-bootstrap';


const Ouragents = () => {
    return (
        <div className="container" style={{ marginBottom: '500px', marginTop: '100px' }}>
        
        <Card className="text-center mb-4">
          
            
          <Card.Header>Comming Soon</Card.Header> {/* Include the price */}
          <br/>
          <Card.Body>
           
         
            
           
          </Card.Body>
        </Card>
         </div>
      );
}

export default Ouragents;