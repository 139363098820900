import React from "react";
import Best from '../best/Best';
import Hero from '../hero/Hero';
import Featured from "../featured/Featured";





const Home = () =>{
    return(
        <div>
            <Hero/>
            <Best/>
            <Featured/>
            
        </div>
    )
}

export default Home;